import React, { useReducer } from 'react';


const initialState = {
  user: null,
};


const UserContext: any = React.createContext({});

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'updateUser':
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};


const UserProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UserContext.Provider>
  );
};


export { UserContext, UserProvider, initialState };
