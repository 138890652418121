import React, { Suspense } from 'react';
import { UserProvider } from '@src/context/UserContext';

const Layout = ({ children }) =>
  <Suspense
    fallback={<></>}
  >
  <UserProvider>
    {children}
  </UserProvider>
</Suspense>;

export default Layout;
