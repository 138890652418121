import './styles/app.less';
import './styles/tailwind.css';
import './styles/modern-normalize.css';
import '@tea/component/tea.css';
import 'swiper/less';
import 'swiper/less/pagination';
import 'swiper/less/navigation';
import 'swiper/less/effect-fade';
// 导入依赖
import { RouteItem, app } from '@tencent/sim-tea-app';
import Layout from './layout/Layout';
// 引入await async 运行环境
import 'regenerator-runtime/runtime.js';
import { lazy } from 'react';
import './utils/aegis';
// 导入依赖

// 路由代码
type IRoute = RouteItem & {
  title?: string;
  titleEn?: string;
};


const routes: IRoute[] = [
  {
    path: '/logout',
    component: lazy(() => import('@src/routes/Logout/Logout')),
  },
  {
    path: '/', // 门户console/contribute-team
    component: lazy(() => import('@src/layout/FrontedLayout')),
    children: [
      {
        path: '/',
        component: lazy(() => import(/* webpackChunkName: "home" */ '@src/routes/Home')),
      },
      {
        path: '/projects',
        component: lazy(() => import(/* webpackChunkName: "home" */ '@src/routes/Projects')),
      },
    ],
  },
  // { path: '*', component: lazy(() => import('@src/routes/Home')) },
  { path: '*', redirect: '/' },
];
// 注册路由表/左侧菜单
app.routes(routes, Layout);
